import {Controller} from "@hotwired/stimulus"


export default class extends Controller {

    connect() {
        const del_btn = this.element.getElementsByClassName('delete-button')[0]
        const string_input = this.element.querySelector('#query_string')

        string_input.addEventListener('keyup', () => this.handle_typing(string_input))
        this.handle_typing(string_input)


        del_btn.addEventListener('click', () => {
            string_input.value = ''
            string_input.focus()
            this.handle_typing(string_input)
            this.element.submit()
        })
    }

    handle_typing(input) {
        if (input.value === '') {
            this.element.classList.remove('has-text')
        } else {
            this.element.classList.add('has-text')
        }
    }
}