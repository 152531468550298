import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    connect() {

        const save_btn = document.getElementById('save-project-button')
        save_btn.addEventListener('click', () => this.save_form())

        const cancel_btn = document.getElementById('cancel-project-form-button')
        cancel_btn.addEventListener('click', () => this.cancel_form())

    }

    save_form() {
        const btn = this.element.querySelector('#project-form input[type="submit"]')
        btn.click()
    }

    cancel_form() {
        const btn = this.element.querySelector('#project-form .cancel-form-button')
        btn.click()
    }

}